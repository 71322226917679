<template>
    <div class="text-box">
        <el-form ref="form" :model="form" label-width="80px" :rules="rules">
            <el-form-item label="控件名称" prop="title" required>
                <el-input v-model="form.title"></el-input>
            </el-form-item>
            <el-form-item label="控件说明" prop="explaination" required>
                <el-input type="textarea" v-model="form.explaination"></el-input>
            </el-form-item>
            <el-form-item label="字数限制" required>
                <el-input-number v-model="form.max_length" ></el-input-number>
            </el-form-item>
            <el-form-item label="关键字">
                <div class="inline">
                    <el-input v-model="form.setting.key.value"></el-input>
                    <div><el-button round size="mini" @click="showShortcut">快捷语</el-button></div>
                </div>
            </el-form-item>
            <el-form-item label="其他">
                <el-checkbox v-model="form.required">必填*</el-checkbox>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="onSubmit">确定</el-button>
            </el-form-item>
        </el-form>
        <!-- dialog -->
        <el-dialog v-loading="loading" :title="form.setting.key.value?'关键字:'+form.setting.key.value:'请输入关键字'" :visible.sync="dialogTableVisible">
                <div class="input-box">
                    <el-input v-model="shortcut" 
                    type="textarea"
                    placeholder="请输入快捷语"
                    autocomplete="off"></el-input>
                    <div class="saveBox">
                        <el-button round type="success" @click="addShortcut" :disabled="!form.setting.key.value">添加</el-button>
                    </div>
                </div>
                <div class="list-box">
                    <div class="list-item" v-for="(item,index) in shortcutList" :key="index">
                        <div class="del">
                            <i class="el-icon-delete" @click="delShortcut(index)"></i>
                        </div>
                        <div class="l-content">
                            <FilterText :data="item.content" :keyword="form.setting.key.value" ></FilterText>
                        </div>
                    </div>
                </div>
                 <el-pagination
                    layout="prev, pager, next"
                    @current-change="pageChange"
                    :page-count="totalPage">
                </el-pagination>
        </el-dialog>
    </div>
</template>
<script>
import FilterText from './FilterText'
export default {
    components:{
        FilterText
    },
    props:{
        page:{
            type:Number
        },
        current:{
            type:Number
        },
        item:{
            type:Object,
        }
    },
    watch:{
        item:{
            handler(val){
                this.form = JSON.parse(JSON.stringify(val))
                if(!this.form.key){
                    this.form.key = {value:''}
                }
            },
            immediate:true,
            deep:true
        }
    },
    data(){
        var title = (rule, value, callback) => {
        var pattern = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？ ]");
        if(pattern.test(value)){
            callback(new Error('请勿输入特殊字符'));
            }
            callback();
        };
        return{
            loading:false,
            dialogTableVisible:false,
            shortcut:"",//关键内容
            shortcutList:[],
            currentPage:1,
            totalPage:1,
            form:{
            },
            rules:{
                title:[
                    { validator: title, trigger: 'blur' }
                ],
                explaination:[
                    { validator: title, trigger: 'blur' }
                ]
            }
        }
    },
    methods:{
        pageChange(e){
            this.currentPage=e;
            this.getShortCut()
        },
        addShortcut(){
            if(!this.shortcut||!this.form.setting.key.value){
                this.$message({
                        message: '请输入快捷语句',
                        type: 'warning'
                    });
                return
            }
                this.loading = true
            let params={
                method:"addShortcut",
                keyword:this.form.setting.key.value,
                content:this.shortcut
            }
            this.$ajax.post(this.API.api,params).then(()=>{
                    this.loading = false 
                    this.shortcut=''
                    this.$message({
                        message: '添加成功',
                        type: 'success'
                    });
                    this.getShortCut()
                }).catch(err=>{
                    console.log(err)
                    this.loading = false 
                    this.$message({
                        message: err.msg||this.CONST.FAIL_TIME_OUT,
                        type: 'warning'
                    });
                })
        },
        showShortcut(){
            this.dialogTableVisible=true 
            this.getShortCut()
        },
        delShortcut(index){
            this.loading = true
            let params={
                method:"delShortcut",
                id:this.shortcutList[index].id
            }
            this.$ajax.post(this.API.api,params).then(({data})=>{
                    this.loading = false 
                    this.$message({
                        message: '删除成功'||data,
                        type: 'success'
                    });
                    this.getShortCut()
                }).catch(err=>{
                    console.log(err)
                    this.loading = false 
                    this.$message({
                        message: err.msg||this.CONST.FAIL_TIME_OUT,
                        type: 'warning'
                    });
                })
        },
        getShortCut(){
            if(this.currentPage>this.totalPage){
                this.$message({
                        message: '已全部加载',
                        type: 'warning'
                    });
                return
            }
            this.loading = true
            let params={
                method:"searchShortcut",
                key:this.form.setting.key.value,
                page:this.currentPage
            }
            //console.log(params)
            this.$ajax.post(this.API.api,params).then(({data})=>{
                    this.loading = false 
                    //console.log(data)
                    this.shortcutList=data
                    if(data[0]&&data[0].totalPage){
                        this.totalPage = data[0].totalPage
                    }
                }).catch(err=>{
                    this.loading = false 
                    console.log(err.data)
                    this.$message({
                        message: err.msg||this.CONST.FAIL_TIME_OUT,
                        type: 'warning'
                    });
                })
        },
        onSubmit(){
            if(typeof(this.item)!="object"){
                this.$message('请勿入输入特殊字符串！');
                return
            }
            this.$refs['form'].validate((valid) => {
                if(valid){
                    let data = {
                        page:this.page,
                        current:this.current,
                        item:this.form
                    }
                    this.$emit('save',data)
                }
            })
        }
    }
}
</script>
<style lang="less">
.inline{
    display: flex;
    div{
        margin-left: 10px;
    }
}
.saveBox{
    text-align: center;
    margin:10px auto;
}
.list-box{
    .list-item{
        display: flex;
        padding:10px 5px;
        border-bottom: 1px #fbfbfb solid;
        .del{
            line-height: 20px;
            margin:2px 5px;
            cursor:pointer;
        }
        .del:hover{
            color:red;
        }
    }
    .list-item:hover{
        background: #f8f8f8;
    }
}

</style>