<template>
    <div class="text-box">
        <el-form ref="form" :model="form" label-width="80px" :rules="rules">
            <el-form-item label="控件名称" prop="title" required>
                <el-input v-model="form.title"></el-input>
            </el-form-item>
            <el-form-item label="控件说明" prop="explaination" required>
                <el-input type="textarea" v-model="form.explaination"></el-input>
            </el-form-item>
            <el-form-item label="计算类型" required>
                <el-radio v-model="form.setting.calculate" label="start_end">开始至结束</el-radio>
                <el-radio v-model="form.setting.calculate" label="time_span">间隔{{form.setting.diff}}分钟内拍照1次</el-radio>
            </el-form-item>
            <el-form-item label="计算间隔" v-if="form.setting.calculate!='start_end'" required>
               <el-input-number v-model="form.setting.diff" :min="1" :max="100" label="分钟"></el-input-number>
            </el-form-item>
            <el-form-item label="签退">
                <el-checkbox v-model="form.setting.signOut">签退</el-checkbox>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="onSubmit">确定</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
export default {
    props:{
        page:{
            type:Number
        },
        current:{
            type:Number
        },
        item:{
            type:Object,
        }
    },
    watch:{
        item:{
            handler(val){
                this.form = JSON.parse(JSON.stringify(val))
                if(!this.form.key){
                    this.form.key = {value:''}
                }
            },
            immediate:true,
            deep:true
        }
    },
    data(){
        var title = (rule, value, callback) => {
        var pattern = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？ ]");
        if(pattern.test(value)){
            callback(new Error('请勿输入特殊字符'));
            }
            callback();
        };
        return{
            form:{
            },
            rules:{
                title:[
                    { validator: title, trigger: 'blur' }
                ],
                explaination:[
                    { validator: title, trigger: 'blur' }
                ]
            }
        }
    },
    methods:{
        onSubmit(){
            if(typeof(this.item)!="object"){
                this.$message('请勿入输入特殊字符串！');
                return
            }
            this.$refs['form'].validate((valid) => {
                if(valid){
                    let data = {
                        page:this.page,
                        current:this.current,
                        item:this.form
                    }
                    this.$emit('save',data)
                }
            })
        }
    }
}
</script>
<style lang="less">

</style>