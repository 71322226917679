<template>
    <div class="pageNavB">
            <van-cell :title="ntitle" border>
                <template #icon v-if="page!=0">
                    <i class="icon el-icon-arrow-left" @click="next(0)"/>
                    <i class="icon el-icon-delete-solid" @click="del"/>
                </template>
                <template #right-icon>
                    <i v-if="length-1>page" class="icon el-icon-arrow-right" @click="next(true)"/>
                    <slot></slot>
                </template>
            </van-cell>
        </div>
</template>
<script>
export default {
    props:{
        ntitle:{
            type:String
        },
        page:{
            type:Number
        },
        length:{
            type:Number
        }
    },
    methods:{
        next(i){
            let page = i?this.page+1:this.page-1
            this.$emit('next',page)
        },
        //删除
        del(){
            if(this.page==0){
                return;
            }
            this.$emit('del',this.page)
        }
    }
}
</script>
<style lang="less">
.fixed{
    width:100%;
    height: 40px;
}
.pageNavB{
    .van-cell{
        background-color:#5cb87a;
        color:#fff;
    }
}
.icon {
    margin-right:10px;
    font-size: 16px;
    line-height: inherit;
  }
</style>