<template>
    <div class="text-box">
        <el-form ref="form" :model="form" label-width="80px" :rules="rules">
            <el-form-item label="控件名称" prop="title" required>
                <el-input v-model="form.title"></el-input>
            </el-form-item>
            <el-form-item label="控件说明" prop="explaination" required>
                <el-input type="textarea" v-model="form.explaination"></el-input>
            </el-form-item>
            <el-form-item label="添加选项" required>
                <div class="radioBox" v-for="(item1,index) in form.setting.list" :key="index">
                    <el-col :span="11">
                    <el-input type="text" v-model="form.setting.list[index].name"></el-input>
                    </el-col>
                    <el-col :span="11">
                    <i class="Icon el-icon-remove" :class="index==0?'disabled':''" @click="del(index)"/>
                    <i class="Icon el-icon-circle-plus" @click="add(index)"/>
                    </el-col>
                </div>
            </el-form-item>
            <el-form-item label="其他">
                <el-checkbox v-model="form.required">必填*</el-checkbox>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="onSubmit">确定</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
export default {
    props:{
        page:{
            type:Number
        },
        current:{
            type:Number
        },
        item:{
            type:Object,
        }
    },
    watch:{
        item:{
            handler(val){
                this.form = JSON.parse(JSON.stringify(val))
            },
            immediate:true,
            deep:true
        }
    },
    data(){
        var title = (rule, value, callback) => {
        var pattern = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？ ]");
        if(pattern.test(value)){
            callback(new Error('请勿输入特殊字符'));
            }
            callback();
        };
        return{
            form:{},
            rules:{
                title:[
                    { validator: title, trigger: 'blur' }
                ],
                explaination:[
                    { validator: title, trigger: 'blur' }
                ]
            }
        }
    },
    methods:{
        //添加选项
        add(index){
            let data = {
                    "name": "新增选项", 
                    "checked": false, 
                    "disabled": false
                }
            this.form.setting.list.splice((index+1),0,data)
        },
        //删除选项
        del(index){
            if(index>=1){
                this.form.setting.list.splice(index,1)
            }
        },
        onSubmit(){
            if(typeof(this.item)!="object"){
                this.$message('请勿入输入特殊字符串！');
                return
            }
            this.$refs['form'].validate((valid) => {
                if(valid){
                    let data = {
                        page:this.page,
                        current:this.current,
                        item:this.form
                    }
                    this.$emit('save',data)
                }
            })
        }
    }
}
</script>
<style lang="less">
.radioBox{
    padding:10px 0;
    height: auto;
    clear:both;
}
.Icon.disabled{
    color: #DBDBDB;
    cursor:default;
}
.Icon{
    margin:0 15px;
    font-size: 1.2em;
    cursor: pointer;
}
</style>