<template>
    <div class="list-box">
        <div class="list">
             <!-- list列表 -->
              <van-field v-model="item.title" label="" right-icon="setting-o" @click-right-icon="listAddSetting=true"/>
                <ListBox v-for="(item,index) in arrayList" :index="index" @click="listClick"
                         color="#ffa631"    :key="index" :isCurrent="current==index" @close="close">
                                <!-- 输入框 -->
                                <van-field 
                                    v-if="item.menu=='text'"
                                    rows="2"
                                    disabled
                                    autosize
                                    :label="item.title"
                                    type="textarea"
                                    :maxlength="item.max_length"
                                    :placeholder="item.explaination"
                                    show-word-limit
                                    />
                                <!-- 轻提示 -->
                                <div class="notice" v-else-if="item.menu=='tip'">
                                    {{item.explaination}}
                                </div>
                                <!-- 图片uploader -->
                                <div class="uploader" v-else-if="item.menu=='upload'">
                                    <van-cell :title="item.title" />
                                    <van-uploader/>
                                </div>
                                <!-- 数字 -->
                                <van-cell v-else-if="item.menu=='input'" :icon="item.icon"
                                 :title="item.title" :value="item.explaination" />
                                <!-- 单选 -->
                                <van-cell v-else-if="item.menu=='radio'" :icon="item.icon"
                                 :title="item.title" :value="item.explaination" />
                                 <!-- 多选 -->
                                <van-cell v-else-if="item.menu=='checkBox'" :icon="item.icon"
                                 :title="item.title" :value="item.explaination" />
                                 <!-- 联动选 -->
                                <van-cell v-else-if="item.menu=='selector'" :icon="item.icon"
                                 :title="item.title" :value="item.explaination" />
                </ListBox>
        </div>
        <div class="btn-box">
            <div class="btn-item">
                            <el-popover
                            placement="top-start"
                            title="流程控件"
                            width="400"
                            trigger="hover">
                            <div class="list-con">
                                <!-- list -->
                                <van-grid clickable>
                                <van-grid-item v-for="(item,index) in widgetList" :key="index"
                                 :icon="item.icon" :text="item.title" @click="click(index,item.title)"/>
                                </van-grid>
                            </div>
                            <el-button slot="reference" type="text" icon="el-icon-plus"  >
                                添加列表
                            </el-button>
                        </el-popover>
            <!-- <el-button type="primary">+ 添加流程</el-button> -->
        </div>
        </div>
        <!-- 弹出框 -->
        <el-drawer
        :title="title"
        :visible.sync="drawer"
        :direction="direction"
        :modal="false"
        :before-close="handleClose">
        <div class="addBox">
            <FormText :current="current" :page="page" v-if="arrayList[current]&&arrayList[current].menu=='text'" 
                            :item="arrayList[current]" @save="saveWidget"/>
                        <FormUpload  :current="current" :page="page" v-else-if="arrayList[current]&&arrayList[current].menu=='upload'" 
                            :item="arrayList[current]" @save="saveWidget"/>
                        <FormTip  :current="current" :page="page" v-else-if="arrayList[current]&&arrayList[current].menu=='tip'" 
                            :item="arrayList[current]" @save="saveWidget"/>
                        <FormRadio  :current="current" :page="page" v-else-if="arrayList[current]&&arrayList[current].menu=='radio'" 
                            :item="arrayList[current]" @save="saveWidget"/>
                        <FormCheckbox  :current="current" :page="page" 
                        v-else-if="arrayList[current]&&arrayList[current].menu=='checkBox'" 
                            :item="arrayList[current]" @save="saveWidget"/>
                        <FormInput  :current="current" :page="page" 
                        v-else-if="arrayList[current]&&arrayList[current].menu=='input'" 
                            :item="arrayList[current]" @save="saveWidget"/>
                        <FormSelector  :current="current" :page="page" 
                        v-else-if="arrayList[current]&&arrayList[current].menu=='selector'" 
                            :item="arrayList[current]" @save="saveWidget"/>
        </div>
        </el-drawer>
        <!-- listAdd设置框 -->
        <el-dialog title="列表设置" :visible.sync="listAddSetting">
            <van-cell-group inset>
            <van-field v-model="item.mininum" label="最少条数" placeholder="可限制最少输入条数"/>
            <van-field v-model="item.split" label="分隔符号" placeholder="如','将'产区,大堂'计算为两条数据"/>
            </van-cell-group>
        </el-dialog>
    </div>
</template>
<script>
import ListBox from '@/components/ListBox.vue';
import FormText from '@/components/widget/FormText.vue'
import FormUpload from '@/components/widget/FormUpload.vue'
import FormTip from '@/components/widget/FormTip.vue'
import FormRadio from '@/components/widget/FormRadio.vue'
import FormCheckbox from '@/components/widget/FormCheck.vue'
import FormSelector from '@/components/widget/FormSelector.vue'
import FormInput from '@/components/widget/FormInput.vue'
var widgetList = require('@/utils/widgetList.json');//引入组件列表
export default {
    components:{
        FormInput,
        ListBox,
        FormText,
        FormUpload,
        FormTip,
        FormRadio,
        FormCheckbox,
        FormSelector
    },
    props:{
        item:{
            type:Object
        },
        list:{
            type:Array,
        },
        currentIndex:{
            type:Number
        },
        pageIndex:{
            type:Number
        }
    },
    data(){
        return{
            listAddSetting:false,//显示设置
            title:'',
            widgetList:JSON.parse(JSON.stringify(widgetList)),
            arrayList:[],
            drawer:false,
            direction:'rtl',
            currentMenu:{},
            current:0,
            page:0
        }
    },watch:{
        item:{
            handler(){
                let list = []
                this.widgetList.forEach(item=>{
                    if(item.menu!='tip'&&item.menu!='sign'&&item.menu!='nav'&&item.menu!="ListAdd"){
                        list.push(item)
                    }
                })
                this.widgetList = list 
                console.log(this.item)
                if(this.item.setting.list){
                    this.arrayList = this.item.setting.list
                }
            },
            immediate:true,
            deep:true
        }
    },
    created(){
        //初始化列表
    },
    methods:{
        init(){
            
        },
        close(index){
            console.log(index)
            this.arrayList.splice(index,1)
            this.drawer = false 
            this.toSaveData()
        },
        listClick(index){
            this.current = index
            this.$forceUpdate()
            this.drawer = true 
            this.toSaveData()
        },
        saveWidget(data){
            //console.log(data.item)
            if(this.arrayList[this.current]){
                //更改
                this.arrayList[this.current] = data.item
            }else{
                this.arrayList.push(data.item)
            }
            this.item.setting.list = this.arrayList
            //console.log(this.arrayList)
            this.toSaveData()
            // let backData = {
            //     page:this.pageIndex,
            //     current:this.currentIndex,
            //     item:this.item
            // }
            // this.$emit('save',backData)
            // this.$forceUpdate()
            // this.$message({
            //         message: '保存成功！',
            //         type: 'success'
            //     });
        },
        handleClose(){
            this.drawer = false
        },
        click(index,title){
            //this.currentMenu = this.widgetList[index] 
            this.drawer = true 
            this.title = title
            this.arrayList.push(this.widgetList[index] )
            this.toSaveData()
            //console.log(this.currentMenu)
        },
        toSaveData(){
            let backData = {
                page:this.pageIndex,
                current:this.currentIndex,
                item:this.item
            }
            this.$emit('save',backData)
            this.$forceUpdate()
        }
    }
}
</script>
<style lang="less">
.title{
    width:100%;
    height:40px;
    line-height: 40px;
    text-align: left;
    font-size: 1.0em;
    border-bottom: 1px #DBDBDB solid;
}
.addBox{
    padding:5px 10px;
}
</style>