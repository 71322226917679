<template>
    <div class="text-box">
        <el-form ref="form" :model="form" label-width="80px" :rules="rules">
            <el-form-item label="控件名称" required>
                <el-input v-model="form.title" prop="title"></el-input>
            </el-form-item>
            <el-form-item label="数量限制" required>
                <el-input-number v-model="form.max_length" :max="21"></el-input-number>
            </el-form-item>
            <el-form-item label="其他">
                <el-checkbox v-model="form.required">必填*</el-checkbox>
                <el-checkbox v-model="camera" @change="cachange">只拍照</el-checkbox>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="onSubmit">确定</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
export default {
    props:{
        page:{
            type:Number
        },
        current:{
            type:Number
        },
        item:{
            type:Object,
        }
    },
    watch:{
        item:{
            handler(val){
                this.form = val
                if(val.setting.sourceType&&val.setting.sourceType.length==1){
                    this.camera = true
                }
            },
            immediate:true,
            deep:true
        }
    },
    data(){
        var title = (rule, value, callback) => {
        var pattern = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？ ]");
        if(pattern.test(value)){
            callback(new Error('请勿输入特殊字符'));
            }
            callback();
        };
        return{
            camera:false,
            form:{},
            rules:{
                title:[
                    { validator: title, trigger: 'blur' }
                ]
            }
        }
    },
    methods:{
        cachange(e){
            if(e){
                this.form.setting.sourceType = ['camera']
            }else{
                this.form.setting.sourceType = ['album','camera']
            }
            console.log(this.form,'___this.form')
        },
        onSubmit(){
            if(typeof(this.item)!="object"){
                this.$message('请勿入输入特殊字符串！');
                return
            }
            this.$refs['form'].validate((valid) => {
                if(valid){
                    let data = {
                        page:this.page,
                        current:this.current,
                        item:this.form
                    }
                    this.$emit('save',data)
                }
            })
        }
    }
}
</script>
<style lang="less">

</style>