/**
 * 引导元素信息配置
 * 上一步下一步按钮文字都可以配置
 */
 export default [
    {
      element: '#addBtn',  //设置需要引导的元素id
      popover: {
        title: '添加控件',
        description: '点击添加控件设置表单内容',
        position: 'top',
        className: 'first-step-popover-class', //自定义样式
        nextBtnText: '(1/3)已知晓，下一步' //这里也可以自定义下一步按钮上的文字哦
      }
    },
    {
      element: '#addPage',
      popover: {
        title: '添加页面',
        description: '当控件过多时，也可以添加页面',
        position: 'top',
        className: 'spareemailstyle',
        doneBtnText: '(2/3)下一步'
      }
    },{
      element: '#saveBtn',
      popover: {
        title: '保存表单',
        description: '添加内容后记得保存表单',
        position: 'top',
        className: 'spareemailstyle',
        doneBtnText: '(3/3)我知道了'
      }
    }
  ];
  
  