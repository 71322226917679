<template>
    <div class="box" :style="{'border-color':isCurrent?color:''}"
     @click="click"  @contextmenu.prevent="mouseclick">
        <div class="ic" v-if="isCurrent" :style="{'background':isCurrent?color:''}">
            <i class="el-icon-close" @click="close"></i>
        </div>
        <div class="con" id="content">
            <slot></slot>
        </div>
    </div>
</template>
<script>
export default {
    props:{
        index:{
            type:Number,
        },
        isCurrent:{
            type:Boolean,
            default:false
        },
        color:{
            type:String,
            default:'#39F'
        }
    },
    data(){
        return{

        }
    },
    methods:{
        mouseclick(e){
            this.$emit("mouseclick",{index:this.index,e});
        },
        click(){
            this.$emit("click",this.index)
        },
        close(){
            this.$emit("close",this.index)
        }
    }
}
</script>
<style lang="less">

.box{
    border:2px #f0efef solid;
    position:relative;
    padding:10px 15px;
    background: #fff;
    .ic{
        width:20px;
        height:20px;
        color:#fff;
        text-align: center;
        line-height: 20px;
        position:absolute;
        cursor: pointer;
        right:0;
        top:0;
        z-index: 10;
    }
    .ic:hover{
        color:#f78585;
    }
    .con{
        width:100%;
    }
}

</style>