<template>
    <div class="phone-box">
        <div class="phone-header">
            <div class="camera"></div>
        </div>
        <div class="phone-screen">
            <slot></slot>
        </div>
        <div class="phone-btn">
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            
        }
    }
}
</script>
<style lang="less">
    .phone-box{
        width:320px;
        height:560px;
        background: #333;
        border-radius: 20px;
        padding:20px 10px;
        .phone-header{
            width:100%;
            height:20px;
            line-height: 20px;
            .camera{
                width:10px;
                height:10px;
                border:1px #999 solid;
                border-radius: 5px;
                background: #222;
                margin:0 auto;
            }
        }
        .phone-screen{
            background: #f8f8f8;
            width:100%;
            padding-bottom:10px;
            height:460px;
            overflow-x: hidden;
            overflow-y: scroll;
            display: flex;
            flex-direction: column;
        }
        .phone-btn{
            width:60px;
            height:60px;
            cursor: pointer;
            background: linear-gradient(#DBDBDB,#F8F8F8,#999);
            border-radius: 50%;
            border:3px #fff solid;
            margin: 10px auto;
        }
        .phone-btn:hover{
            border:3px rgb(250, 224, 169) solid;
        }
    }
</style>
