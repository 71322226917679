<template>
    <div class="list-box">
        <div class="list">
            <!-- list -->
             <van-field v-model="item.title" label="" />
        </div>
        <div class="nav-list">
             <van-cell :required="item.required" v-for="(item,index) in list" @click="editNavItem(index)"
              :key="index" border clickable is-link>
              <div class="slot-required" title="必填">
                  <el-checkbox v-model="item.required"></el-checkbox>
              </div>
              <div slot="title">
                  {{item.title}}
                  <i @click.stop="deleteItem(index)" class="el-icon-remove-outline" />
              </div>
             </van-cell>
        </div>
        <div class="btn-box">
            <div class="btn-item">
                        <el-popover
                            placement="top-start"
                            title="流程控件"
                            width="400"
                            trigger="hover">
                            <div class="list-con">
                                <!-- list -->
                                <el-input placeholder="请输入流程名" v-model="navName" >
                <el-button slot="append" type="primary" @click="InputName">提交</el-button></el-input>
                                <div class="radio">
                                    <van-cell title="类型">
                                    <!-- right-icon -->
                                    <div slot="label">
                                        <el-radio v-model="navType" label="">默认</el-radio>
                                        <el-radio v-model="navType" label="device">设备检查</el-radio>
                                        <el-radio v-model="navType" label="usage">物料使用</el-radio>
                                    </div>
                                    </van-cell>
                                    <van-cell title="必填*">
                                    <div slot="right-icon">
                                        <el-checkbox v-model="required"></el-checkbox>
                                    </div>
                                    </van-cell>
                                </div>
                            </div>
                            <el-button slot="reference" type="text" icon="el-icon-plus"  >
                                添加流程
                            </el-button>
                        </el-popover>
            <!-- <el-button type="primary">+ 添加流程</el-button> -->
        </div>
        </div>
        <!-- 弹出设备设置 -->
        <!-- <el-dialog title="设备巡检设置" :visible.sync="showDeviceSet">
            <van-cell title="检查规则*">
            <div slot="right-icon">
                <el-radio @change="changeRules" v-model="checkRule" :label="0">关闭</el-radio>
                <el-radio @change="changeRules" v-model="checkRule" :label="1">开启</el-radio>
            </div>
            </van-cell>
        </el-dialog> -->
        <!-- 弹出框 -->
        <el-drawer
        :visible.sync="drawer"
        :direction="direction"
        size="60%"
        :before-close="handleClose">
        <div class="slot-title" style="display:flex" slot="title">
            <el-button :type="type" round @click="save">保存</el-button>
            <!-- {{title}} -->
            <van-field v-if="list[page]" v-model="list[page].title" label="标题" @input="changeTitle"/>
            <!-- <i class="el-icon-delete" style="margin-right:30px;" @click="deleteIndex"></i> -->
        </div>
        <div class="flex-box">
            <div class="left">
                <Phone>
                    <!-- phone -->
                        <div class="list-box" v-if="list.length>=1&&list[page].pages">
                            <!-- list列表 -->
                            <ListBox v-for="(item,index) in list[page].pages" :index="index" @click="listClick"
                             :key="index" :isCurrent="current==index" @close="close" @mouseclick="mouseclick">
                                <!-- 输入框 -->
                                <van-field 
                                    v-if="item.menu=='text'"
                                    rows="2"
                                    disabled
                                    autosize
                                    :label="item.title"
                                    type="textarea"
                                    :maxlength="item.max_length"
                                    :placeholder="item.explaination"
                                    show-word-limit
                                    />
                                <!-- 轻提示 -->
                                <div class="notice" v-else-if="item.menu=='tip'">
                                    {{item.explaination}}
                                </div>
                                <!-- 图片uploader -->
                                <div class="uploader" v-else-if="item.menu=='upload'">
                                    <van-cell :title="item.title" />
                                    <van-uploader/>
                                </div>
                                <!-- 单选 -->
                                <van-cell v-else-if="item.menu=='input'" :icon="item.icon"
                                 :title="item.title" :value="item.explaination" />
                                <!-- 单选 -->
                                <van-cell v-else-if="item.menu=='radio'" :icon="item.icon"
                                 :title="item.title" :value="item.explaination" />
                                 <!-- 多选 -->
                                <van-cell v-else-if="item.menu=='checkBox'" :icon="item.icon"
                                 :title="item.title" :value="item.explaination" />
                                 <!-- 联动选 -->
                                <van-cell v-else-if="item.menu=='selector'" :icon="item.icon"
                                 :title="item.title" :value="item.explaination" />
                                 <!-- 添加列表 -->
                                 <AddList v-else-if="item.menu=='ListAdd'" :item="item" @save="saveWidget"/>
                                 <!-- 添加流程 -->
                                 <AddNav v-else-if="item.menu=='nav'" :item="item" />
                                <!-- floatmenu浮动菜单 -->
                                <FloatMenu :list="floatMenus" @hide="hideMenu" @click="menuClick"
                                :top="menuStyle.top" :left="menuStyle.left" v-if="showMenu&&current==index"/>
                            </ListBox>
                        </div>
                    <!-- phone-end -->
                    <div class="btn">
                     <div class="btn-item">
                            <el-popover
                            placement="top-start"
                            title="控件列表"
                            width="400"
                            trigger="hover">
                            <div class="list-con">
                                <!-- list -->
                                <van-grid clickable>
                                <van-grid-item v-for="(item,index) in widgetList" :key="index"
                                 :icon="item.icon" :text="item.title" @click="click(index)"/>
                                </van-grid>
                            </div>
                            <el-button slot="reference" type="text" icon="el-icon-plus"  >
                                添加控件
                            </el-button>
                        </el-popover>
                        </div>
                    </div>
                </Phone>
            </div>
            <div class="addBox" v-if="list[page]&&list[page].pages&&list[page].pages[current]">
                <FormInput :current="current" :page="page" v-if="list[page].pages[current].menu&&list[page].pages[current].menu=='input'" 
                            :item="list[page].pages[current]" @save="saveWidget"/>
                        <FormText :current="current" :page="page" v-if="list[page].pages[current].menu&&list[page].pages[current].menu=='text'" 
                            :item="list[page].pages[current]" @save="saveWidget"/>
                        <FormUpload  :current="current" :page="page" v-else-if="list[page].pages[current].menu&&list[page].pages[current].menu=='upload'" 
                            :item="list[page].pages[current]" @save="saveWidget"/>
                        <FormTip  :current="current" :page="page" v-else-if="list[page].pages[current].menu&&list[page].pages[current].menu=='tip'" 
                            :item="list[page].pages[current]" @save="saveWidget"/>
                        <FormRadio  :current="current" :page="page" v-else-if="list[page].pages[current].menu&&list[page].pages[current].menu=='radio'" 
                            :item="list[page].pages[current]" @save="saveWidget"/>
                        <FormCheckbox  :current="current" :page="page" 
                        v-else-if="list[page].pages[current].menu&&list[page].pages[current].menu=='checkBox'" 
                            :item="list[page].pages[current]" @save="saveWidget"/>
                        <FormSelector  :current="current" :page="page" 
                        v-else-if="list[page].pages[current].menu&&list[page].pages[current].menu=='selector'" 
                            :item="list[page].pages[current]" @save="saveWidget"/>
        </div>
        </div>
        </el-drawer>
    </div>
</template>
<script>
import FormText from '@/components/widget/FormText.vue'
import FormUpload from '@/components/widget/FormUpload.vue'
import FormTip from '@/components/widget/FormTip.vue'
import FormRadio from '@/components/widget/FormRadio.vue'
import FormCheckbox from '@/components/widget/FormCheck.vue'
import FormSelector from '@/components/widget/FormSelector.vue'
import FormInput from '@/components/widget/FormInput.vue'
import AddList from '@/components/widget/AddList.vue';
import ListBox from '@/components/ListBox.vue';
import Phone from '../Phone.vue';
var widgetList = require('@/utils/widgetList.json');//引入组件列表
export default {
    components:{
        FormInput,
        AddList,
        ListBox,
        Phone,
        FormText,
        FormUpload,
        FormTip,
        FormRadio,
        FormCheckbox,
        FormSelector
    },
    props:{
        item:{
            type:Object
        },
        currentIndex:{
            type:Number
        },
        pageIndex:{
            type:Number
        }
    },
    data(){
        return{
            type:"success",
            required:true,
            list:[],
            showMenu:false,
            navName:'',
            navType:'',
            title:'',
            widgetList:widgetList,
            arrayList:[],
            drawer:false,
            direction:'rtl',
            currentMenu:{},
            current:0,
            page:0,
            showDeviceSet:true,//显示设备巡检规则
            checkRule:0//设备巡检规则
        }
    },
    created(){
        //初始化列表
        let list = []
        this.widgetList.forEach(item=>{
            if(item.menu!='tip'&&item.menu!='sign'&&item.menu!='nav'){
                list.push(item)
            }
        })
        this.widgetList = list 
        console.log(this.item)
        this.list = this.item.setting.list
        this.$forceUpdate()
        console.log(this.list)
    },
    methods:{
        //删除设备/物料使用
        deleteItem(index){
            console.log(index)
            this.page = index 
            this.deleteIndex()
        },
        deleteIndex(){
            //console.log(this.list[this.page])
            this.$confirm('确定删除该项吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                    this.list.splice(this.page,1)
                    this.$message({
                        message: "删除成功！",
                        type: 'success'
                    });
                    this.drawer = false
                }).catch(() => {         
                });
        },
        changeTitle(e){
            console.log(e)
        },
        changeRules(){
            //console.log(e)
            this.save()
        },
        mouseclick(i){
            console.log(i,'0000')
        },
        ///删除
        close(index){
            this.list[this.page].pages.splice(index,1)
            this.$forceUpdate()
            this.type = "success"
        },
        //编辑流程
        editNavItem(index){
            this.page = index
            if(this.list[this.page].type){
                let msg = this.list[this.page].type=='device'?'点击模拟跳转到设备页':'点击模拟跳转到物料使用记录页'
                this.$message({
                    message: msg,
                    type: 'success'
                });
                //显示设备检查设置
                if(this.list[this.page].type=='device'){
                    console.log(this.list[this.page])
                    this.showDeviceSet = true
                }
                return
            }
            this.title = this.list[this.page].title
            this.drawer = true
        },
        //添加nav名称
        InputName(){
            console.log(this.navName)
            if(this.navName){
                this.list.push({
                            "title":this.navName,
                            "required":this.required,
                            "explaination":"",
                            "action":"next",
                            "data":"",
                            "type":this.navType,
                            "status":0,
                            "show_in_report":true,
                            "pages":[]
                })
                this.navName = ''
            }
            console.log(this.list[this.page])
        },
        listClick(index){
            this.current = index 
        },
        saveWidget(data){
            if(this.list[this.page].pages[this.current]){
                //更改
                this.list[this.page].pages[this.current]= data.item
            }
            this.item.setting.list = this.list
            let backData = {
                page:this.pageIndex,
                current:this.currentIndex,
                item:this.item
            }
            this.$emit('save',backData)
            this.$forceUpdate()
        },
        //保存数据
        save(){
            let backData = {
                page:this.pageIndex,
                current:this.currentIndex,
                item:this.item
            }
            this.type = "info"
            this.$emit('save',backData)
        },
        handleClose(){
            this.drawer = false
        },
        click(index){
            this.currentMenu = JSON.parse(JSON.stringify(this.widgetList[index]))
            this.drawer = true 
            this.type = "success"
            this.list[this.page].pages.push(this.currentMenu)
            console.log(this.currentMenu)
        }
    }
}
</script>
<style lang="less">
.title{
    width:100%;
    height:40px;
    line-height: 40px;
    text-align: left;
    font-size: 1.0em;
    border-bottom: 1px #DBDBDB solid;
}
    span.el-checkbox__inner{
            color:#999;
        font-size: 12px;
    }

.flex-box{
    display: flex;
    padding:12px 10px;
    .left{
        flex:1;
    }
    .addBox{
        flex:1;
    }
    .radio{
        padding:2px 4px;
    }
}
.btn{
    width:100%;
    height:35px;
    border-top:1px #DBDBDB solid;
    padding:5px 0;
    text-align: center;
    line-height: 35px;
    display: flex;
    .btn-item{
        flex:1;
        text-align: center;
    }
}
.el-icon-remove-outline{
    color: rgb(168, 7, 7);
}
</style>