<template>
    <div class="text-box">
        <el-form ref="form" :model="form" label-width="80px" :rules="rules">
            <el-form-item label="提示文字" prop="title" required>
                <el-input v-model="form.explaination"></el-input>
            </el-form-item>
            <el-form-item label="延时">
                <el-input-number v-model="form.setting.delay.value" ></el-input-number>
            </el-form-item>
            <el-form-item label="显示时长">
                <el-input-number v-model="form.setting.duration.value" ></el-input-number>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="onSubmit">确定</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
export default {
    props:{
        page:{
            type:Number
        },
        current:{
            type:Number
        },
        item:{
            type:Object,
        }
    },
    watch:{
        item:{
            handler(val){
                this.form = JSON.parse(JSON.stringify(val))
            },
            immediate:true,
            deep:true
        }
    },
    data(){
        var title = (rule, value, callback) => {
        var pattern = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？ ]");
        if(pattern.test(value)){
            callback(new Error('请勿输入特殊字符'));
            }
            callback();
        };
        return{
            form:{},
            rules:{
                title:[
                    { validator: title, trigger: 'blur' }
                ],
                explaination:[
                    { validator: title, trigger: 'blur' }
                ]
            }
        }
    },
    methods:{
        onSubmit(){
            if(typeof(this.item)!="object"){
                this.$message('请勿入输入特殊字符串！');
                return
            }
            this.$refs['form'].validate((valid) => {
                if(valid){
                    let data = {
                        page:this.page,
                        current:this.current,
                        item:this.form
                    }
                    this.$emit('save',data)
                }
            })
        }
    }
}
</script>
<style lang="less">

</style>