<template>
    <div class="filterBox" v-html="text">
    </div>
</template>
<script>
export default {
    props:{
        data:{
            type:String
        },
        keyword:{
            type:String
        }
    },
    data(){
        return {
            text:''
        }
    },
    watch:{
        data:{
            handler(val){
                if(this.keyword){
                    let reg = new RegExp(this.keyword,'g')
                    this.text = val.replace(reg,"<font color='red'>"+this.keyword+'</font>')
                }else{
                    this.text = val
                }
            },
            immediate:true,
            deep:true
        }
    },
}
</script>