<template>
    <div class="text-box">
        <el-form ref="form" :model="form" label-width="80px" :rules="rules">
            <el-form-item label="控件名称" prop="title" required>
                <el-input v-model="form.title"></el-input>
            </el-form-item>
            <el-form-item label="控件说明" prop="explaination" required>
                <el-input type="textarea" v-model="form.explaination"></el-input>
            </el-form-item>
            <el-form-item label="添加选项" required>
                <div class="sel-box">
                    <div class="sel-item">
                        <div class="sel-line" :class="index==current1?'seleted':''" @click="select(index)"
                        v-for="(item1,index) in list" :key="index">
                            <div class="left">
                                {{item1.label}}
                                <i class="icon el-icon-remove-outline"  
                                @click.stop="del(index)"/>
                            </div>
                            <div class="right">
                                <i class="icon el-icon-arrow-right" />
                            </div>
                        </div>
                        <div class="btn-box">
                            <el-input v-if="input1" v-model="input" @keyup.enter.native="addIn()"
                             placeholder="请输入内容后，回车"></el-input>
                            <el-button type="text" icon="el-icon-plus" @click="input1=true">添加</el-button>
                        </div>
                    </div>
                    <div class="sel-item">
                        <div class="v" v-if="list[current1]&&list[current1].children">
                            <div class="sel-line"
                            :class="index==current2?'seleted':''"  @click="select2(index)"
                            v-for="(item2,index) in list[current1].children" :key="index">
                                <div class="left">
                                    {{item2.label}}
                                    <i class="icon el-icon-remove-outline" @click="del2(index)"/>
                                </div>
                                <div class="right">
                                    <i class="icon el-icon-arrow-right" />
                                </div>
                            </div>
                        </div>
                        <div class="btn-box">
                            <el-input v-if="input2" v-model="input" @keyup.enter.native="addIn2()"
                             placeholder="请输入内容后，回车"></el-input>
                            <el-button type="text" icon="el-icon-plus" @click="input2=true">添加</el-button>
                        </div>
                    </div>
                    <div class="sel-item">
                        <div class="v"  v-if="list[current1]&&list[current1].children[current2]">
                            <div class="sel-line"
                            v-for="(item3,index) in list[current1].children[current2].children"
                            :key="index">
                                {{item3.label}}
                                <i class="icon el-icon-remove-outline" @click="del3(index)"/>
                            </div>
                        </div>
                        <div class="btn-box">
                            <el-input v-if="input3" v-model="input" @keyup.enter.native="addIn3()"
                             placeholder="请输入内容后，回车"></el-input>
                            <el-button type="text" icon="el-icon-plus" @click="input3=true">添加</el-button>
                        </div>
                    </div>
                </div>
            </el-form-item>
            <el-form-item label="其他">
                <el-checkbox v-model="form.required">必填*</el-checkbox>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="onSubmit">确定</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
export default {
    props:{
        page:{
            type:Number
        },
        current:{
            type:Number
        },
        item:{
            type:Object,
        }
    },
    watch:{
        item:{
            handler(val){
                this.form = JSON.parse(JSON.stringify(val))
                this.list = this.form.setting.list
            },
            immediate:true,
            deep:true
        }
    },
    data(){
        var title = (rule, value, callback) => {
        var pattern = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？ ]");
        if(pattern.test(value)){
            callback(new Error('请勿输入特殊字符'));
            }
            callback();
        };
        return{
            list:[],
            input3:false,
            input2:false,//显示
            input1:false,//显示
            input:'',//临时内容
            current1:0,
            current2:0,
            form:{},
            rules:{
                title:[
                    { validator: title, trigger: 'blur' }
                ],
                explaination:[
                    { validator: title, trigger: 'blur' }
                ]
            }
        }
    },
    methods:{
        //添加第三级
        addIn3(){
            this.list[this.current1].children[this.current2].children.push({
                 "label": this.input, 
                 "value": this.input
            })
            this.input = ''
            this.input3 = false
        },
        //添加第二级
        addIn2(){
            this.list[this.current1].children.push({
                "label": this.input, 
                "value": this.input, 
                "children": []
            })
            this.input = ''
            this.input2 = false
        },
        //添加第一级
        addIn(){
            this.list.push({
                "label": this.input, 
                "value": this.input, 
                "children": []
            })
            this.input = ''
            this.input1 = false
        },
        //删除
        del(index){
            // if(index==0){
            //     return
            // }
            this.resetCurrent()
            if(this.list[index]){
                this.list.splice(index,1)
            }
        },
        del2(index){
            if(this.list[this.current1].children){
                this.list[this.current1].children.splice(index,1)
            }
            this.resetCurrent()
        },
        del3(index){
            if(this.list[this.current1].children[this.current2].children){
                this.list[this.current1].children[this.current2].children.splice(index,1)
            }
            this.resetCurrent()
        },
        resetCurrent(){
            this.current1 = 0
            this.current2 = 0
        },
        //选择
        select2(index){
            this.current2 = index
        },
        //选择
        select(index){
            this.current1 = index 
            this.current2 = 0
        },
        onSubmit(){
            if(typeof(this.item)!="object"){
                this.$message('请勿入输入特殊字符串！');
                return
            }
            if(this.list.length<=0){
                this.$message('请输入选项！');
                return;
            }
            this.form.setting.list = this.list
            this.$refs['form'].validate((valid) => {
                if(valid){
                    let data = {
                        page:this.page,
                        current:this.current,
                        item:this.form
                    }
                    this.$emit('save',data)
                }
            })
        }
    }
}
</script>
<style lang="less">
.sel-box{
    display: flex;
    min-width:360px;
    height: auto;
    border:1px solid #f8f8f8;
    .seleted{
        background: #F8F8F8;
    }
    .sel-item{
        border-right: 1px solid #F8F8F8;
        flex:1;
        .sel-line{
            width:96%;
            cursor: pointer;
            display: flex;
            line-height: 36px;
            height: 36px;
            padding:5px 2%;
            .left{
                flex:1;
            }
            .icon{
                line-height: inherit;
            }
            .icon.disabled{
                color: #DBDBDB;
                cursor:default;
            }
        }
    }
}
</style>