<template>
        <div class="m-box" :style="{top,left}">
        <div class="m-list" v-for="(item,index) in list" :key="index" @click="click(index)">
            <i :class="item.icon" v-if="item.icon" />
            {{item.title}}
        </div>
    </div>
</template>
<script>
export default {
    props:{
        top:{
            type:[Number,String],
            default:0
        },
        left:{
            type:[Number,String],
            default:0
        },
        list:{
            type:Array,
        }
    },
    data(){
        return{

        }
    },
    methods:{
        hide(){
            this.$emit('hide')
        },
        click(index){
            this.$emit('click',this.list[index])
        }
    }
}
</script>
<style lang="less">
.mask{
    position:fixed;
    z-index: 1;
    width:100%;
    height: 100%;
    top:0;
    left:0;
}
.m-box{
    width:160px;
    border-radius: 5px;
    height: auto;
    position:absolute;
    top:0;
    left:0;
    z-index: 10;
    background: #fff;
    overflow: hidden;
    box-shadow: 10px 10px 50px 0px rgba(0,0,0,0.75);
    -webkit-box-shadow: 10px 10px 50px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 10px 10px 50px 0px rgba(0,0,0,0.75);
    .m-list{
        width:96%;
        padding:0 3%;
        line-height: 30px;
        height: 30px;
        border-bottom: 1px #F8F8F8 solid;
        cursor: pointer;
        font-size: 12px;
        color:#666;
    }
    .m-list:hover{
        background: #F8F8F8;
        color:#333;
    }
}
</style>