<template>
    <div class="f-add-box">
        <el-row>
            <el-col :span="12">
                <Phone>
                    <!-- 页面导航 -->
                    <PageNav :ntitle="list[page].title" :length="list.length" @del="delPage"
                     :page="page" @next="next"/>
                    <div class="content" id="listBox" ref="obtain">
                        <el-empty v-if="list[page].list.length<=0" description="请添加控件">
                        </el-empty>
                        <div class="list-box">
                            <!-- list列表 -->
                            <ListBox v-for="(item,index) in list[page].list" :index="index" @click="listClick"
                             :key="index" :isCurrent="current==index" @close="close" @mouseclick="mouseclick">
                                
                                <!-- 输入框 -->
                                <van-field 
                                    v-if="item.menu=='text'"
                                    rows="2"
                                    disabled
                                    autosize
                                    :label="item.title"
                                    type="textarea"
                                    :maxlength="item.max_length"
                                    :placeholder="item.explaination"
                                    show-word-limit
                                    />
                                <!-- 签到 -->
                                <div class="notice sign" v-else-if="item.menu=='sign'">
                                    {{item.explaination}}
                                </div>
                                <!-- 轻提示 -->
                                <div class="notice" v-else-if="item.menu=='tip'">
                                    {{item.explaination}}
                                </div>
                                <!-- 图片uploader -->
                                <div class="uploader" v-else-if="item.menu=='upload'">
                                    <van-cell :title="item.title" />
                                    <van-uploader/>
                                </div>
                                <!-- 单选 -->
                                <!-- <van-cell v-else-if="item.menu=='input'" :icon="item.icon"
                                 :title="item.title" :value="item.explaination" /> -->
                                 <!-- <van-field v-else-if="item.menu=='input'"  :placeholder="item.explaination"
                                 disabled type="number" :label="item.title" /> -->
                                <van-cell v-else-if="item.menu=='input'" :icon="item.icon"
                                 :title="item.title" :value="item.explaination" />
                                <!-- 单选 -->
                                <van-cell v-else-if="item.menu=='radio'" :icon="item.icon"
                                 :title="item.title" :value="item.explaination" />
                                 <!-- 多选 -->
                                <van-cell v-else-if="item.menu=='checkBox'" :icon="item.icon"
                                 :title="item.title" :value="item.explaination" />
                                 <!-- 联动选 -->
                                <van-cell v-else-if="item.menu=='selector'" :icon="item.icon"
                                 :title="item.title" :value="item.explaination" />
                                 <!-- 签名 -->
                                <van-cell v-else-if="item.menu=='signature'" :icon="item.icon"
                                 :title="item.title" :value="item.explaination" />
                                 <!-- 添加列表 -->
                                 <AddList v-else-if="item.menu=='ListAdd'" :item="item"/>
                                 <!-- 添加流程 -->
                                 <AddNav v-else-if="item.menu=='nav'" @save="saveWidget" :item="item" :currentIndex="current" :pageIndex="page" />
                                <!-- floatmenu浮动菜单 -->
                                <FloatMenu :list="floatMenus" @hide="hideMenu" @click="menuClick"
                                :top="menuStyle.top" :left="menuStyle.left" v-if="showMenu&&current==index"/>
                            </ListBox>
                            <!-- list列表 -->
                        </div>
                    </div>
                    <div class="btn">
                         <div class="btn-item">
                             <el-button id="addPage" @click="addPage" type="text" icon="el-icon-folder-add">添加页面</el-button>
                         </div>
                        <div class="btn-item">
                            <el-popover
                            placement="top-start"
                            title="控件列表"
                            width="400"
                            trigger="hover">
                            <div class="list-con">
                                <!-- list -->
                                <van-grid clickable>
                                <van-grid-item v-for="(item,index) in widgetList" :key="index"
                                 :icon="item.icon" :text="item.title" @click="click(index)"/>
                                </van-grid>
                            </div>
                            <el-button id="addBtn" slot="reference" type="text" icon="el-icon-plus"  >
                                添加控件
                            </el-button>
                        </el-popover>
                        </div>
                    </div>
                </Phone>
            </el-col>
            <el-col :span="12">
                <div class="info-box">
                    <!-- 基本信息 -->
                     <el-tabs v-model="activeName" @tab-click="handleClick">
                        <el-tab-pane label="基本信息" name="first">
                            <el-form ref="form" :model="form" label-width="80px">
                                <el-form-item label="表单名称" required>
                                    <el-input v-model="form.name" placeholder="输入备注文字"></el-input>
                                </el-form-item>
                                <el-form-item label="备注">
                                    <el-input v-model="form.remark"></el-input>
                                </el-form-item>
                                <el-form-item>
                                    <el-button id="saveBtn" icon="el-icon-circle-check" type="success" @click="saveData">保存表单</el-button>
                                </el-form-item>
                            </el-form>
                        </el-tab-pane>
                        <el-tab-pane label="配置管理" name="second">
                            <!-- tab2 -->
                        </el-tab-pane>
                    </el-tabs>
                    
                </div>
                <div class="setting-box" >
                    <!-- widget配置 -->
                    <FormInput :current="current" :page="page" v-if="list[page].list[current]&&list[page].list[current].menu=='input'" 
                            :item="list[page].list[current]" @save="saveWidget"/>
                        <FormText :current="current" :page="page" v-if="list[page].list[current]&&list[page].list[current].menu=='text'" 
                            :item="list[page].list[current]" @save="saveWidget"/>
                        <FormUpload  :current="current" :page="page" v-else-if="list[page].list[current]&&list[page].list[current].menu=='upload'" 
                            :item="list[page].list[current]" @save="saveWidget"/>
                        <FormTip  :current="current" :page="page" v-else-if="list[page].list[current]&&list[page].list[current].menu=='tip'" 
                            :item="list[page].list[current]" @save="saveWidget"/>
                        <FormRadio  :current="current" :page="page" v-else-if="list[page].list[current]&&list[page].list[current].menu=='radio'" 
                            :item="list[page].list[current]" @save="saveWidget"/>
                        <FormCheckbox  :current="current" :page="page" 
                        v-else-if="list[page].list[current]&&list[page].list[current].menu=='checkBox'" 
                            :item="list[page].list[current]" @save="saveWidget"/>
                        <FormSelector  :current="current" :page="page" 
                        v-else-if="list[page].list[current]&&list[page].list[current].menu=='selector'" 
                            :item="list[page].list[current]" @save="saveWidget"/>
                        <FormNav  :current="current" :page="page" 
                        v-else-if="list[page].list[current]&&list[page].list[current].menu=='nav'" 
                            :item="list[page].list[current]" @save="saveWidget"/>
                        <FormNav  :current="current" :page="page" 
                        v-else-if="list[page].list[current]&&list[page].list[current].menu=='ListAdd'" 
                            :item="list[page].list[current]" @save="saveWidget"/>
                        <FormSign  :current="current" :page="page" 
                        v-else-if="list[page].list[current]&&list[page].list[current].menu=='sign'" 
                            :item="list[page].list[current]" @save="saveWidget"/>
                </div>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import driverSteps from '@/views/Form/add_steps.js'
//引入组件
import Vue from 'vue';
import { Icon, Grid, GridItem,Field,Uploader,Cell ,CellGroup} from 'vant';

Vue.use(Field).use(Grid).use(CellGroup).use(GridItem).use(Uploader).use(Cell).use(Icon);
import Phone from '@/components/Phone.vue';
const widgetList = require('@/utils/widgetList.json');//引入组件列表
import ListBox from '@/components/ListBox.vue';
import FloatMenu from '@/components/FloatMenu.vue';
import AddList from '@/components/widget/AddList.vue';
import AddNav from '@/components/widget/AddNav.vue';
import PageNav from '@/components/widget/PageNav.vue'
import FormText from '@/components/widget/FormText.vue'
import FormUpload from '@/components/widget/FormUpload.vue'
import FormTip from '@/components/widget/FormTip.vue'
import FormRadio from '@/components/widget/FormRadio.vue'
import FormCheckbox from '@/components/widget/FormCheck.vue'
import FormSelector from '@/components/widget/FormSelector.vue'
import FormNav from '@/components/widget/FormNav.vue'
import FormSign from '@/components/widget/FormSign.vue'
import FormInput from '@/components/widget/FormInput.vue'
export default {
    components:{
        FormInput,
        FormSign,
        FormNav,
        Phone,
        ListBox,
        FloatMenu,
        AddList,
        AddNav,
        PageNav,
        FormText,
        FormUpload,
        FormTip,
        FormRadio,
        FormCheckbox,
        FormSelector
    },
    data(){
        return {
            updateId:"",
            content:{},
            update:false,
            activeName:'first',
            form:{
                name:'新建表单'
            },
            current:0,
            page:0,
            list:[ //表单
                {
                    title:'第1页',
                    list:[]
                }
            ],
            widgetList,
            floatMenus:[
                {title:'上移',icon:'el-icon-caret-top'},
                {title:'下移',icon:'el-icon-caret-bottom'}
            ],
            showMenu:false,
            menuStyle:{
                left:0,
                top:0
            }
        }
    },
    created(){
        this.update = this.$route.query.update
        //console.log(this.update)
        if(this.update){
             this.content = JSON.parse(localStorage.getItem("currentForm"))
             this.list = JSON.parse(this.content.content)
             this.updateId = this.content.id
             this.form = {
                 name:this.content.name,
                 remark:this.content.remark
             }
             console.log(this.list)
        }
    },
    mounted(){
        this.guide()
    },
    methods:{
        guide(){
            //引导页
            let hasGuide = window.localStorage.getItem("hasGuide_addForm")
            if(!hasGuide){
                this.$Driver.defineSteps(driverSteps)
                this.$Driver.start()
                window.localStorage.setItem("hasGuide_addForm",'true')
            }
            
        },
        //保存
        saveWidget(data){
            this.list[this.page].list[this.current] = data.item
            this.$forceUpdate()
            console.log(this.list)
            this.$message({
                 type: 'success',
                 message: '保存成功！'
            });  
        },
        //删除页面
        delPage(page){
            if(this.list[page].list.length>=1){
                this.$confirm('确定删除第'+(page+1)+'页吗？', '提示', {
                   confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                    }).then(() => {
                        this.yesDel(page)
                    }).catch(() => {
                        this.$message({
                            type: 'info',
                            message: '已取消删除'
                        });          
                    });
            }else{
                this.yesDel(page)
            }
        },
        yesDel(page){
            this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                        this.page --
                        this.current = 0
                        this.list.splice(page,1)
        },
        //切换页面
        next(page){
            console.log(page)
            this.page = page
        },
        //添加页面
        addPage(){
            this.list.push({
                title:'第'+(this.list.length+1)+'页',
                list:[]
            })
            this.page ++
            console.log(this.list)
        },
        //tabbar切换
        handleClick(tab, event) {
            console.log(tab, event);
        },
        //点击菜单
        menuClick(e){
            let last
            switch(e.title){
                case '上移':
                    if(this.current==0){
                        return;
                    }
                     last = this.current-1
                    break;
                case '下移':
                    if(this.current>=(this.list[this.page].list.length-1)){
                        return;
                    }
                     last = this.current+1
                    break;
            }
                    this.list[this.page].list = this.elChangeExForArray(this.current,last,this.list[this.page].list)
        },
        // 下标为index1元素与下标为index2元素交换位置返回新数组
        elChangeExForArray (index1, index2, array) {
            if(!array[index1]||!array[index2]){
                return array
            }
            let temp = array[index1]
            array[index1] = array[index2]
            array[index2] = temp
            return array
        },
        //隐藏菜单
        hideMenu(){
            this.showMenu = false
        },
        //右键显示操作菜单
        mouseclick(e){
            this.current = e.index
            this.showMenu = true 
            console.log(e.e.offsetX,e.e.offsetY)
            this.menuStyle = {
                top:e.e.offsetY+'px',
                left:e.e.offsetX+'px'
            }
            this.$forceUpdate()
        },
        listClick(e){
            this.current = e
            this.showMenu = false
        },
        //添加列表
        click(index){
            //console.log(index)
            //console.log(widgetList[index])
            let widget = JSON.parse(JSON.stringify(widgetList))
            if(widget[index].menu!='sign'){
                this.list[this.page].list.push(widget[index])
                this.current = this.list[this.page].list.length-1
            }else{
                this.list[this.page].list.unshift(widget[index])
                this.current = 0
            }
           // console.log(this.list)
            this.scrollToBottom()
           // console.log(this.list[this.page].list[this.current].menu)
        },
        //删除
        close(index){
            this.list[this.page].list.splice(index,1)
            this.current --;
        },
        scrollToBottom: function () {
            let container = this.$el.querySelector("#listBox");
            this.$nextTick(() => {
                container.scrollTo(0,container.scrollHeight)
                //container.scrollTop = container.scrollHeight;
                this.$forceUpdate()
            });
        },
        //保存
        saveData(){
            let params = this.form 
            params.method = "addForms"
            console.log(this.list)
            params.content = JSON.stringify(this.list)
            console.log(params.content)
            if(this.updateId&&this.updateId!=''){
                params.updateId = this.update
            }
            if(this.update){
                //修改
                params.updateId = this.updateId
            }
            if(this.list[this.page].list.length<=0){
                this.$message({
                    message: '表单为空！',
                    type: 'warning'
                });
                return
            }
            this.$ajax.post(this.API.api,params).then(res=>{
                console.log(res)
                this.$message({
                    message: '保存成功！',
                    type: 'success'
                });
            }).catch(err=>{
                //Err
                console.log(err)
                this.loading=false
                this.$message({
                    message: err.msg?err.msg:err,
                    type: 'warning'
                });
            })
        }
    }
}
</script>
<style lang="less">
.content{
    flex:1;
    overflow-y: scroll;
    position: relative;
    .notice{
        width: 100%;
        height:35px;
        line-height: 35px;
        text-align: center;
        color:white;
        background: rgb(72, 131, 14);
    }
    .sign{
        background: rgb(216, 78, 43);
    }
}
.btn{
    width:100%;
    height:35px;
    border-top:1px #DBDBDB solid;
    padding:5px 0;
    text-align: center;
    line-height: 35px;
    display: flex;
    .btn-item{
        flex:1;
        text-align: center;
    }
}
.setting-box{
    border-top:1px solid #dbdbdb;
    margin-top:20px;
    padding-top:20px;
}
</style>